import { GlobalMetaTags } from '@/compositions/MetaInfo'
import { AuthProvider } from '@/context/auth'
import { BookingProvider } from '@/context/booking'
import { CancelBookingProvider } from '@/context/cancelBooking'
import { NavigationProvider } from '@/context/navigation'
import { UIProvider } from '@/context/ui'
import '@/styles/bundle.css'
import '@/styles/font-inter.css'
import '@/styles/font-open-sans.css'
import '@/styles/globals.css'
import '@/styles/responsive-style.ts'
// CSS Imports
import { ExternalAuthHandler } from '@/components/ExternalAuthHandler'
import { FeatureToggleProvider } from '@/context/featureToggles'
import { ModalProvider } from '@/context/modal'
import UrqlProvider from '@/urql/URQLProvider'
import DialogAction from '@/widgets/DialogAction'
import 'modern-css-reset/dist/reset.min.css'
import { appWithTranslation } from 'next-i18next'
import { AppProps } from 'next/app'
import { useRouter } from 'next/router'
import NextNProgress from 'nextjs-progressbar'
import 'resize-observer-polyfill/dist/ResizeObserver.global'

function GSUWeb({ Component, pageProps }: AppProps): JSX.Element {
  const router = useRouter()
  const isDialogPreviewEnabled = !!router.query.dialogPreview
  const isForcedDialog = !!router.query.d
  return (
    <UrqlProvider pageProps={pageProps}>
      <FeatureToggleProvider>
        <GlobalMetaTags title="GetSetUp: Live classes for older adults" />
      </FeatureToggleProvider>

      <UIProvider>
        <AuthProvider>
          <ExternalAuthHandler>
            <FeatureToggleProvider>
              <NavigationProvider>
                <CancelBookingProvider>
                  <BookingProvider>
                    <NextNProgress color="#ff4600" showOnShallow={true} options={{ showSpinner: false }} />
                    <Component {...pageProps} />
                  </BookingProvider>
                </CancelBookingProvider>
                {isForcedDialog ? (
                  <ModalProvider initialState={{ isOpen: true }}>
                    <DialogAction action={{ dialogType: String(router.query.d) }} />
                  </ModalProvider>
                ) : null}
                {isDialogPreviewEnabled ? (
                  <ModalProvider initialState={{ isOpen: true }}>
                    <DialogAction action={{ dialogType: String(router.query.dialogPreview) }} />
                  </ModalProvider>
                ) : null}
              </NavigationProvider>
            </FeatureToggleProvider>
          </ExternalAuthHandler>
        </AuthProvider>
      </UIProvider>
    </UrqlProvider>
  )
}

export default appWithTranslation(GSUWeb)
